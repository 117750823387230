import React, { useState, useEffect } from "react";
import {
  HeaderWrapper,
  statusContainer,
  statusAlert,
} from "./AdminHeaderOrganismStyles";
import AdminTablePageHeaderMolecule from "../../molecules/AdminTablePageHeader/AdminTablePageHeaderMolecule";
import TableWidgetsList from "../../molecules/TableWidgetsList/TableWidgetsListMolecule";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import PersonIcon from "@material-ui/icons/Person";
import { useAuthDispatch, useAuthState } from "../../context/AuthContext";
import { logout } from "../../context/actions/authActions";
import { useJobStatus } from "../../context/JobStatusContext";
import { useNotificationCount } from "../../context/NotificationContext";
import useApiService from "../../utilities/useApiService";
import { UserRole } from "../../utilities/Enums";
import { isUserRoleNotEqual } from "../../utilities/checkUserRole";

const NOTIFICATION_POLLING_TIMEOUT = 20000;

export interface AdminHeaderProps {}

const AdminHeader: React.FC<AdminHeaderProps> = () => {
  const { jobStatus, setJobStatus } = useJobStatus();
  const dispatch = useAuthDispatch();
  const [searchOptions] = useState([
    { title: "The Shawshank Redemption" },
    { title: "The Godfather" },
    { title: "The Godfather: Part II" },
    { title: "The Dark Knight" },
    { title: "12 Angry Men" },
    { title: "Schindler's List" },
    { title: "Pulp Fiction" },
  ]);
  const history = useHistory();
  const { user } = useAuthState();
  const { getMethod } = useApiService();
  const { setNotificationCount } = useNotificationCount();

  useEffect(() => {
    if (user && isUserRoleNotEqual(user?.role, UserRole.User)) {
      const notificationInterval = setInterval(async () => {
        try {
          let response = await getMethod(`admin/notifications/unseen-count`);
          let count = response.data;
          setNotificationCount(count);
        } catch (error) {
          console.error("Error fetching notification count:", error);
        }
      }, NOTIFICATION_POLLING_TIMEOUT);
  
      return () => clearInterval(notificationInterval);
    }
  }, [user, getMethod, setNotificationCount]);
  
  useEffect(() => {
    return () => {
      setJobStatus(null);
    };
  }, [setJobStatus]);

  return (
    <>
      <HeaderWrapper>
        <AdminTablePageHeaderMolecule
          searchOptions={searchOptions}
          selectOptions={[
            {
              onClick: () => {
                history.push("/admin/pharmacy/add");
              },
              label: "Add New Pharmacy",
              icon: <></>,
            },
            {
              onClick: () => {
                history.push("/admin/forget/password");
              },
              label: "Reset Password",
              icon: <></>,
            },
            {
              onClick: () => {
                const logoutPath =
                user?.role !== UserRole.Admin ? "/signin" : "/admin/login";
                history.push(logoutPath);
                logout(dispatch);
              },
              label: "Log out",
              icon: <></>,
            },
          ]}
          selectHeaderLabel={`${user?.username}`}
          selectHeaderIcon={<PersonIcon />}
        />
        {jobStatus && (
          <Box sx={statusContainer}>
            <Alert severity="info" sx={statusAlert}>
              <AlertTitle>Import Status: {jobStatus}</AlertTitle>
            </Alert>
          </Box>
        )}
      </HeaderWrapper>
      <TableWidgetsList />
    </>
  );
};

export default AdminHeader;
