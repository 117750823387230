export const itemEntry = [
  [
    {
      controllingName: "ndc",
      placeHolder: "NDC",
      type: "text",
      helperText: "NDC",
      rules: {
        required: "This Field is Required",
        validate: (value: string) => {
          let inputNumber = value.replace(/[^0-9]/g, "");
          if (inputNumber.length < 9) {
            return "NDC number must be at least 9 digits long";
          } else if (inputNumber.length > 13) {
            return "NDC number should be maximum 11 numbers";
          } else {
            return true;
          }
        },
      },
      disabled: false,
    },
  ],
  [
    {
      controllingName: "quantityType",
      placeHolder: "Full/Partial",
      radioGroupTitle: "Full/Partial",
      type: "radio",
      isRadioGroup: true,
      radioButtons: [
        { label: "Full", value: "full" },
        { label: "Partial", value: "partial" }
      ],
      radioDefaultValue:"full",
      rules: {
        required: "This Field is Required",
      },
      disabled: false,
    },
    {
      controllingName: "quantity",
      placeHolder: "Quantity",
      helperText: "Quantity",
      type: "text",
      disabled: false,
      rules: {
        required: "This Field is Required",
        validate: {
          nonNegative: (value: number) => {
            if (value < 0) {
              return "Please enter a non-negative number";
            }
            return true;
          },
          integer: (value: string) => {
            const num = parseFloat(value);
            if (!Number.isInteger(num)) {
              return "Please enter a whole number";
            }
            return true;
          }
        }
      },
    },
  ],
  [
    {
      controllingName: "WACPrice",
      placeHolder: "WAC Price",
      helperText: "WAC Price",
      type: "number",
      disabled: true,
    },
    {
      controllingName: "estimatedReturnValue",
      placeHolder: "Estimated return value",
      helperText: "Estimated return value",
      type: "number",
      disabled: true,
    }
  ],
  [
    {
      controllingName: "expDate",
      placeHolder: "Exp.Date",
      type: "month",
      helperText: "Expiry Date",
      disabled: false,
      rules: {
        required: "This Field is Required",
      },
    },
    {
      controllingName: "lot",
      placeHolder: "Lot #",
      helperText: "LOT",
      type: "text",
      disabled: false,
      rules: {
        required: "This Field is Required",
        validate: (value: string) => {
          const alphanumericRegex = /^[a-zA-Z0-9-]*$/;
          if (alphanumericRegex.test(value)) {
            return true;
          }
          return "This Field should contain only letters and numbers";
        },
      },
    },
  ],
  [
    {
      controllingName: "gtin14",
      placeHolder: "GTIN14",
      helperText: "GTIN14",
      type: "text",
      disabled: true,
    },
    {
      controllingName: "serialNumber",
      placeHolder: "S/N",
      helperText: "S/N",
      type: "text",
      disabled: true,
    }
  ],
];

export const productDetails = [
  [
    {
      controllingName: "description",
      placeHolder: "Description",
      helperText: "Description",
      disabled: true,
    },
  ],
  [
    {
      controllingName: "manufacturer",
      placeHolder: "Manufacturer",
      helperText: "Manufacturer",
      disabled: true,
    },
  ],
  [
    {
      controllingName: "strength",
      placeHolder: "Strength",
      helperText: "Strength",
      disabled: true,
    },
  ],
  [
    {
      controllingName: "dosage",
      helperText: "Dosage",
      placeHolder: "Dosage",
      disabled: true,
    },
  ],
  [
    {
      controllingName: "pkgSize",
      helperText: "Package Size",
      placeHolder: "Pkg Size",
      disabled: true,
    },
  ],
  [
    {
      controllingName: "controlledSubstanceCode",
      helperText: "Controlled Substance Code",
      placeHolder: "CSC",
      disabled: true,
    },
  ],
];
