import styled from "styled-components";

export const Container = styled.div`
  background: #5a63b1 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 220px;
  height: 85px;
  white-space: nowrap;
  padding: 0 24px 0 24px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: self-end;
  margin-left: 20px;
`;

export const Label = styled.div`
  font: normal normal bold 21px/25px Roboto, Bold;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const NumberLabel = styled.div`
  font: normal normal medium 21px/25px Roboto, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  background: #737abd 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59px;
  width: 59px !important;
  overflow: hidden;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
