import { useQuery } from "react-query";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import { createNotificationsRows, NotificationData } from "./CreateNotificationsTable";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import useApiService from "../../utilities/useApiService";
import { UserRole } from "../../utilities/Enums";
import { useAuthState } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";

export const NotificationsPage = (
) => {
  const { user } = useAuthState();
  const userRole = user?.role ?? "";
  const { getMethod, putMethod } = useApiService();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const [page, setPage] = useState(0);
  const size = 20;

  const getNotificationsApi = async () => {
    startLoading(loaderDispatch);
    let response;
    switch (userRole) {
      case UserRole.Admin:
      case UserRole.Warehouse:
        response = await getMethod(`admin/notifications?page=${page}&size=${size}`);
        break;
    }
    stopLoading(loaderDispatch);
    return response.data;
  };
  
  const { data, refetch } = useQuery(["notifications", page], getNotificationsApi, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data?.content) {
      const visibleNotificationIds = data.content.map((notification: NotificationData) => notification.id);
      if (visibleNotificationIds.length > 0) {
        putMethod(`admin/notifications/update`, JSON.stringify(visibleNotificationIds))
      }
    }
  }, [data]);

  const totalPages = data?.totalPages || 1;
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    refetch();
  };
  const getHeaderTitles = () => {
    let headerTitles: string[] = ["Number", "Created At", "Notification Type", "User", "Creator"];
    return headerTitles;
  };

  const notifications = data?.content || [];
  const rows: Array<TableRowProps> = notifications.length
    ? createNotificationsRows(
      notifications,
    )
    : [];

  return loading ? (
    <Loader />
  ) : (
    <div >
    <TableOrganism header={createHeaderRow(getHeaderTitles())} rows={rows} />
    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }} >
    <Pagination 
      count={totalPages} 
      page={page + 1} 
      onChange={(event, newPage) => handlePageChange(newPage - 1)} 
    />
    </div>
  </div>
  );
};
